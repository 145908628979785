export default [
    {
        url: {
            cv: "/Dominic-Butler-CV.pdf",
            linkedin: "https://www.linkedin.com/in/dominic-butler-816b31119",
            instagram: "https://www.instagram.com/dombutlerpad/",
        },
        text: "Hello and welcome to my personal hub for tech demos and hobby projects. Each slide showcases something different.",
        image: "dom.jpeg",
        color: "#C9FCE8",
    },
    {
        url: {
            project: "https://modular-clock.vercel.app/",
            code: "https://github.com/domb5041/modular-clock",
        },
        text: "A fully customisable modular clock with an array of possible complications. Inspired by the apple watch UI.",
        image: "clock.png",
        color: "#FA3337",
    },
    {
        url: {
            project: "https://bookmark-manager-l9tlugegu-domb5041s-projects.vercel.app",
            code: "https://github.com/domb5041/bookmark-manager",
        },
        text: "A basic url bookmark manager featuring tagging, sorting and search. It has a backend which stores data. The UI has light/dark mode and accent theming.",
        image: "bookmarks.png",
        color: "#B28FFF",
    },
    {
        url: {
            project: "https://19-galaxy-generator-zeta.vercel.app",
        },
        text: "A three.js experiment using 3D paricles to simulate a galaxy system.",
        image: "galaxy.png",
        color: "#9196FB",
    },
    {
        url: {
            project: "https://doms-desktop.vercel.app/",
            code: "https://github.com/domb5041/doms-desktop",
        },
        text: "A replica mac desktop. Demonstrating basic window management and dynamic menus.",
        image: "desktop.jpg",
        color: "#D1FFFD",
    },
    {
        url: {
            project: "https://www.youtube.com/watch?v=JChDbIOkE58&list=PLrRbyFPS5q6w8wLmKAudLnPg1L7CMv1Lw",
        },
        text: "I explain some music theory in a two-part animated video series. Inspired by the illustrations and presentation of Youtube channel Kurzgesagt.",
        image: "notecircle.jpg",
        color: "#FF97B2",
    },
];
